import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ApiService } from 'src/app/services/api.service';
 
declare var grecaptcha: any;

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

    result="Mensaje enviado correctamente";
    color="alert-success";
    alertResult: boolean = true;
    captchaError: boolean = false;
    render: boolean = false;

    constructor(private viewportScroller: ViewportScroller,private apiService:ApiService) {}

    public onClick(elementId: string): void { 
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit(){
    }

    ngAfterViewChecked(){
        if(grecaptcha && !this.render){
            grecaptcha.render('captcha_element', {
                'sitekey': '6LfpF5MeAAAAAIn2KVk78BJiJAss_QLY4tPusJVX'
            });         
            this.render=true;
        }
    }

    submit(form){
        var value=form.value;
        var message = `<b>Cliente:</b> ${value.name}
        <br/><b>Telefono:</b> ${value.number}
        <br/><b>Email:</b> ${value.email}
        <br/><b>Mensaje:</b> ${value.message}`;

        const recaptcha = grecaptcha.getResponse();
        if (recaptcha.length === 0) {
          this.captchaError = true;
          return;
        }

        this.apiService.sendEMail({message:message,recaptcha:recaptcha}).subscribe((data)=>{
            this.alertResult=false;
            this.result="Mensaje enviado correctamente";
            this.color="alert-success";
        },error=>{
            this.alertResult=false;
            this.result="Servicio no disponible.";
            this.color="alert-danger";            
        },()=>{
            setTimeout(this.closeAlert.bind(this), 5000);
            form.reset(); 
            grecaptcha.reset();
        });
    }

    closeAlert(){
        this.alertResult = true;
    }

}