import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
   
  a="EC0FILT";
  b="_WAKVG62MvgY5gt";

  constructor(private httpClient:HttpClient) { }

  getQuery = (query: string, body:any) => {
    const URL = `api/${query}`;
    let headers = {'Content-Type':'application/json; charset=utf-8'};
    headers['Authorization']='Basic '+btoa(this.a+":"+this.b);
    const content = new HttpHeaders(headers);
    return this.httpClient.post(URL,body,{headers:content});
  }
  sendEMail(body:any){
    return this.getQuery("sendemail",body).pipe(map((data: any) => data));
  }
}
