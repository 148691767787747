<nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
    <div class="container">

        <a class="navbar-brand text-uppercase" routerLink="/">
            <span>E</span>c<img class="mb-2" src="assets/img/gota.png" alt="Home Ecofilt"><span>f</span>ilt
        </a>
        <button class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><span class="nav-link" (click)="onClick('home')">Home</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('about')">Nosotros</span></li>
                <!--li class="nav-item"><span class="nav-link" (click)="onClick('services')">Servicios</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('work')">Trabajo</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('blog')">Noticias</span></li-->
                <li class="nav-item"><span class="nav-link" (click)="onClick('contact')">Contacto</span></li>
                <!--li class="nav-item"><span class="nav-link" (click)="onClick('pricing')">Presupuesto</span></li-->
                <li class="nav-item">
                    <a href="https://mail.ionos.es/" target=" _blank " class="nav-link ">
                     Acceso
                    </a>
                </li>
                <!--li class="nav-item"><span class="nav-link" (click)="onClick('team')">Team</span></li-->
            </ul>
            <ul class="navbar-nav ml-auto for-responsive ">
                <li class="nav-item "><span class="nav-link " (click)="toggleClass(); onClick( 'home') ">Home</span></li>
                <li class="nav-item "><span class="nav-link " (click)="toggleClass(); onClick( 'about') ">Nosotros</span></li>
                <!--li class="nav-item "><span class="nav-link " (click)="toggleClass(); onClick( 'services') ">Servicios</span></li>
                <li class="nav-item "><span class="nav-link " (click)="toggleClass(); onClick( 'work') ">Trabajo</span></li>
                <li class="nav-item "><span class="nav-link " (click)="toggleClass(); onClick( 'blog') ">Noticias</span></li>                
                <li class="nav-item "><span class="nav-link " (click)="toggleClass(); onClick( 'pricing') ">Presupuesto</span></li-->
                <li class="nav-item "><span class="nav-link " (click)="toggleClass(); onClick( 'contact') ">Contacto</span></li>
                <li class="nav-item">
                    <a href="https://mail.ionos.es/" target=" _blank " class="nav-link ">
                        Acceso
                    </a>
                </li>
                <!--li class="nav-item "><span class="nav-link " (click)="toggleClass(); onClick( 'team') ">Team</span></li-->
            </ul>
        </div>
    </div>
</nav>