<section class="funfacts-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="far fa-solid fa-thumbs-up"></i>
                    <h3>+<span [countUp]="350">00</span></h3>
                    <p>Clientes Satisfechos</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="fas fa-clipboard-list"></i>
                    <h3>+<span [countUp]="6">00</span></h3>
                    <p>Tipos de Servicios</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="fas fa-award"></i>
                    <h3>+<span [countUp]="18">00</span></h3>
                    <p>Años de Experiencia</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="fas fa-map-marked-alt"></i>
                    <h3>+<span [countUp]="14">00</span></h3>
                    <p>Comarcas de Catalunya</p>
                </div>
            </div>
        </div>
    </div>
</section>