<div id="welcome" class="welcome-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h4>Somos Ecológicos </h4>
            <h2>Bienvenidos a <span>EcoFilt</span></h2>
            <p>Queremos daros la bienvenida a nuestra familia y ofreceros nuestros servicios, soporte y experiencia. Para ayudaros en las tareas de limpieza y reparación de filtros de cocina, campanas extractoras y reciclaje de aceite usado.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <!--i class="far fa-edit"></i-->
                        <img src="assets/img/filtro.png" class="card-img-top" alt="...">
                        <!--img class="p-2" src="assets/img/filtro.png" /-->
                    </div>
                    <h3>Limpieza de Filtros</h3>
                    <p>Lorem ipsum dolor sit amet sit ipsum, consectet adipiscing elit consectetur, ipsum dolor sed do eiusmod tempor.</p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <img class="p-2" src="assets/img/aceite.png" />
                        <!--i class="fas fa-laptop"></i-->
                    </div>
                    <h3>Reciclaje de Aceite Usado</h3>
                    <p>Lorem ipsum dolor sit amet sit ipsum, consectet adipiscing elit consectetur, ipsum dolor sed do eiusmod tempor.</p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-life-ring"></i>
                    </div>
                    <h3>Soporte</h3>
                    <p>Lorem ipsum dolor sit amet sit ipsum, consectet adipiscing elit consectetur, ipsum dolor sed do eiusmod tempor.</p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>
</div>