<div id="contact" class="contact-area ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h4>¿Tienes alguna pregunta?</h4>
            <h2>No lo dudes, <span>contáctanos</span>.</h2>
            <p>Solicita una cita en nuestras oficinas o envíanos el siguiente formulario y te contestaremos lo antes posible.</p>
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm)">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Nombre</label>
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">El nombre es obligatorio.</div>
                                        <div *ngIf="name.errors.minlength">El nombre es demasiado corto</div>
                                        <div *ngIf="name.errors.maxlength">El nombre es demasiado largo</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Email</label>
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email">
                                    <div class="alert alert-danger" *ngIf="email.touched && !email.valid">El Email es obligatorio.</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Teléfono</label>
                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">El teléfono es obligatorio.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label>Mensaje</label>
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="4" class="form-control"></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">El mensaje es obligatorio.</div>
                                </div>
                            </div>
                            <div [hidden]="alertResult" class="col-lg-12 col-md-12">
                                <div class="alert {{color}}" role="alert">{{result}}</div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div id="captcha_element"></div>
                                <div *ngIf="captchaError" class="alert alert-danger">Activar la casilla de verificación.</div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <button type="submit" class="btn btn-primary float-end" [class.disabled]="!contactForm.valid">Enviar Mensaje</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="contact-info">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul>
                                <li>
                                    <a href="https://goo.gl/maps/QwRxC5M5gKo9Yhxu8" target="blank">
                                        <i class="fas fa-map-marker-alt"></i>
                                        <span>Localización:</span>Calle Doctor Auguet 08840, Viladecans, Barcelona.
                                    </a>
                                </li>
                                <li>
                                    <a href="mailto:info@ecofilt.es">
                                        <i class="far fa-envelope"></i>
                                        <span>Email:</span>info@ecofilt.es
                                    </a>
                                </li>
                                <li>
                                    <a href="tel:671213512">
                                        <i class="fas fa-phone-alt"></i><span>Teléfono:</span>(+34) 671 21 35 12
                                    </a>

                                </li>
                                <!--li><i class="fas fa-fax"></i> <a href="tel:61724160055"><span>Fax:</span>+617-241-60055</a></li-->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>