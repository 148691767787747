<div class="main-banner item-bg-one">
    <div class="creative-banner-three"></div>
    <particles [params]="myParams" [style]="myStyle" [width]="width" [height]="height"></particles>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text">
                    <!--h4>En EcoFilt</h4-->
                    <h4>En EcoFilt </h4>
                    <h1>Somos
                        <ngx-typed-js [strings]="['Profesionales','Entusiastas','Únicos','Mejores','Competentes','Cercanos','Amables','Ecológicos','Sostenibles','EcoFilt']" [typeSpeed]="100" [loop]="false" [backSpeed]="100" [showCursor]="false">
                            <span class="typing"></span>
                        </ngx-typed-js>
                    </h1>
                    <p>Somos una empresa de servicios de limpieza especializada en el reciclaje de filtros y aceites de cocina en todo catalunya.
                    </p>
                    <!--a (click)="onClick('about')" class="btn btn-primary">Conocenos</a>
                    <a (click)="onClick('work')" class="btn btn-primary view-work">Nuestro Trabajo</a-->
                </div>
            </div>
        </div>
    </div>
</div>

<!--app-welcome></app-welcome-->

<app-about></app-about>

<!--app-strategy></app-strategy-->

<!--app-who-we-are></app-who-we-are>

<app-cta></app-cta>

<app-skill></app-skill-->

<app-funfacts></app-funfacts>

<!--app-team></app-team>

<app-how-we-work></app-how-we-work>

<app-services></app-services>

<app-why-we-different></app-why-we-different>

<app-work></app-work>

<app-pricing></app-pricing>

<app-faq></app-faq>

<app-feedback></app-feedback>

<app-blog></app-blog>

<app-partner></app-partner>

<app-subscribe></app-subscribe-->

<app-contact></app-contact>