<footer class="footer-area">
    <div class="container">
        <h3 class="text-uppercase">
            <a routerLink="/">
                <span>E</span>c<img class="mb-2" src="assets/img/gota.png" alt="Home Ecofilt"><span>F</span>ilt</a>
        </h3>
        <ul>
            <!--li>
                <a href="#" target="_blank" class="fab fa-facebook-f"></a>
            </li>
            <li>
                <a href="#" target="_blank" class="fab fa-twitter"></a>
            </li>
            <li>
                <a href="#" target="_blank" class="fab fa-linkedin-in"></a>
            </li>
            <li>
                <a href="#" target="_blank" class="fab fa-instagram"></a>
            </li>
            <li>
                <a href="#" target="_blank" class="fab fa-skype"></a>
            </li-->

            <li>
                <a href="#" target="_blank" class="far fa-envelope" href="mailto:info@ecofilt.es"></a>
            </li>
            <li>
                <a href="#" target="_blank" class="fas fa-phone-alt" href="tel:671213512"></a>
            </li>

        </ul>
        <p>Copyright <i class="far fa-copyright"></i>{{year}} Todos los derechos reservados.</p>
    </div>
</footer>

<!--app-demo-sidebar></app-demo-sidebar!-->

<ngx-scrolltop></ngx-scrolltop>