<div id="about" class="about-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Nosotros <span>EcoFilt</span></h2>
            <p>EcoFilt es un proyecto que nace desde la ilusión y la profesionalidad que nos avala. Contamos con más de 18 años de experiencia en el sector de la recogida de aceite vegetal usado.</p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2>Nuestros <span>Servicios</span></h2>
                        <p>Servicios dedicados a la gestión de aceite de cocina usado.</p>
                    </div>
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Facilitamos bidones homologados</li>
                        <li><i class="fas fa-check"></i>Servicio de recogida en 24 horas</li>
                        <li><i class="fas fa-check"></i>Entregamos Certificado</li>
                    </ul>
                    <p>Servicios especializados en filtros de cocina, campanas, conductos y cajas de extracción.</p>
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Limpieza</li>
                        <li><i class="fas fa-check"></i>Mantenimiento</li>
                        <li><i class="fas fa-check"></i>Conservación</li>
                        <li><i class="fas fa-check"></i>Servicio técnico</li>
                        <li><i class="fas fa-check"></i>Venta</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-video">
                    <img src="assets/img/about.png" alt="about">
                    <!--div class="video-btn">
                        <button class="popup-youtube" (click)="ngxSmartModalService.getModal('popupOne').open()"><i class="fas fa-play"></i></button>
                    </div-->
                </div>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12 pt-5">
                <div class="about-content">
                    <div class="section-title">
                        <h2>Nuestros <span>Certificados</span></h2>
                        <p class="text-justify">
                            En Ecofilt ponemos a su disposición la descarga de la autodeclaración de nuestro gestor.
                        </p>
                        <!--p class="text-justify">En Ecofilt contamos con la certificación <em> <b>ISCC</b> International Sustainability and Carbon Certification</em> en Cataluña. Este certificado avala nuestro compromiso con la sostenibilidad y el reciclaje responsable del aceite
                            usado.
                            <br/><br/> Para su comodidad y transparencia, ponemos a su disposición la descarga de nuestros certificados ISCC.
                        </p-->
                    </div>
                    <div class="row justify-content-left">
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <a target="_blank" title="ECOFILT_AUTODECLARACION_ISCC" class="link-btn" href="assets/pdf/ECOFILT_AUTODECLARACION_ISCC_V1.pdf">
                                <div class="single-box p-4">
                                    <div class="icon">
                                        <i class="fa fa-download" aria-hidden="true"></i>
                                    </div>
                                    <h3>Autodeclaración ISCC</h3>
                                </div>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="video-popup">
    <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="https://www.youtube.com/embed/WQ119jAN4Xo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal>
</div>